


















































import { Component, Vue, Prop } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import DisplayTime from '@/components/common/display-time.vue'
import { DeviceLog, converter } from '@/models/deviceLogs'
import deviceCollection from '@/models/devices'

@Component<DeviceItemLogs>({
  components: { DisplayTime },
  created () {
    this.subscribe()
  },
  destroyed () {
    this.destroy()
  }
})
export default class DeviceItemLogs extends Vue {
  @Prop({ type: String, required: true }) id!: string

  ref = deviceCollection.doc(this.id).collection('logs').withConverter(converter)
  unsubscribe: Function | null = null
  // logs: DeviceLog[] = []
  snapshots: firebase.firestore.QueryDocumentSnapshot<DeviceLog>[] = []
  // items: DeviceLog[] = []
  headers = [
    { value: 'id', text: 'id' },
    { value: 'createdAt', text: 'createdAt' },
    { value: 'time', text: 'time' },
    { value: 'version', text: 'version' },
    { value: 'latLng', text: 'latLng' },
    { value: 'azimuth', text: 'azimuth' }
  ]

  loaded = false
  limit = 4
  empty = false

  get firstSnapshot () {
    return this.snapshots[0]
  }

  get lastSnapshot () {
    return this.snapshots[this.snapshots.length - 1]
  }

  get items () {
    return this.snapshots.map(doc => doc.data())
  }

  async shiftSnapshots (snapshots: firebase.firestore.QueryDocumentSnapshot<DeviceLog>[]) {
    const newSnapshots = snapshots.filter(doc => {
      return !this.snapshots.some(oldDoc => oldDoc.id === doc.id)
    })

    while (newSnapshots.length > 0) {
      const snapshot = newSnapshots.pop()
      if (!snapshot) return
      this.snapshots.unshift(snapshot)
    }
  }

  async pushSnapshots (snapshots: firebase.firestore.QueryDocumentSnapshot<DeviceLog>[]) {
    const newSnapshots = snapshots.filter(doc => {
      return !this.snapshots.some(oldDoc => oldDoc.id === doc.id)
    })

    while (newSnapshots.length > 0) {
      const snapshot = newSnapshots.shift()
      if (!snapshot) return
      this.snapshots.push(snapshot)
    }
  }

  subscribe () {
    // if (this.limit > 30) return
    // this.limit += 10
    this.unsubscribe = this.ref.orderBy('createdAt', 'desc').limit(1).onSnapshot(sn => {
      this.loaded = true
      if (sn.empty) return
      // setTimeout(() => {
      //   this.item = doc.data() || null
      // }, 2000)
      // this.snapshots = sn.docs
      // this.items = sn.docs.map(doc => doc.data())
      this.shiftSnapshots(sn.docs)
    })
  }

  async more () {
    const sn = await this.ref.orderBy('createdAt', 'desc').startAfter(this.lastSnapshot).limit(this.limit).get()
    this.empty = sn.empty
    if (sn.empty) return
    this.pushSnapshots(sn.docs)
  }

  onIntersect (entries: unknown, observer: unknown, isIntersecting: unknown) {
    if (isIntersecting) this.more()
  }

  destroy () {
    if (this.unsubscribe) this.unsubscribe()
  }
}
