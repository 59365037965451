import firebase from '@/plugins/firebase'

interface LatLng {
  readonly lat: number;
  readonly lng: number;
}

export class DeviceLog {
  constructor (
    public id: string = '',
    readonly createdAt: Date,
    readonly version: string,
    readonly time: Date,
    readonly latLng: LatLng,
    readonly azimuth: number
  ) {
  }
}

export const converter: firebase.firestore.FirestoreDataConverter<DeviceLog> = {
  toFirestore (model: Partial<DeviceLog>) {
    return model
  },
  fromFirestore (snapshot: firebase.firestore.QueryDocumentSnapshot) {
    const data = snapshot.data()

    return new DeviceLog(
      snapshot.id,
      (data.createdAt as firebase.firestore.Timestamp)?.toDate(),
      data.version,
      (data.time as firebase.firestore.Timestamp)?.toDate(),
      data.latLng,
      data.azimuth
    )
  }
}
