
















import { Vue, Component, Prop } from 'vue-property-decorator'
import { Device } from '@/models/devices'

@Component<DisplayUpdateEnd>({})
export default class DisplayUpdateEnd extends Vue {
  @Prop({ type: Object }) readonly device!: Device

  get progress () {
    const progress = this.device.progress
    if (!progress) return { index: 0, count: 0 }
    return progress
  }

  get percent () {
    if (!this.progress) return 0
    return this.progress.index * 100 / this.progress.count
  }

  get text () {
    return '완료\n업데이트 파일이 전송되었습니다\n재시작 대기중'
  }
}
