






















import { Vue, Component, Prop } from 'vue-property-decorator'
import { Device } from '@/models/devices'

@Component<DisplayUpdateStart>({})
export default class DisplayUpdateStart extends Vue {
  @Prop({ type: Object }) readonly device!: Device

  get progress () {
    const progress = this.device.progress
    if (!progress) return { index: 0, count: 0 }
    return progress
  }

  get percent () {
    if (!this.progress) return 0
    return this.progress.index * 100 / this.progress.count
  }

  get text () {
    return `업데이트중 (${this.progress.index} / ${this.progress.count})\n진행중입니다`
  }
}
