










import { Vue, Component, Prop } from 'vue-property-decorator'
import { Device } from '@/models/devices'

@Component
export default class DisplayLog extends Vue {
  @Prop({ type: Object }) readonly device!: Device
  status = [
    { color: null, text: '로깅안함', icon: 'mdi-pause' },
    { color: 'success', text: '로깅중', icon: 'mdi-autorenew mdi-spin' }
  ]

  get view () {
    const i = this.device.log ? 1 : 0
    return this.status[i]
  }
}
