
































































































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import GmapCustomMarker from 'vue2-gmap-custom-marker'
import deviceCollection, { Device, Params } from '@/models/devices'
import firmwareCollection, { Firmware } from '@/models/firmwares'
import DisplayTime from '@/components/common/display-time.vue'
import DisplayUpdateStart from '@/components/common/display-update-start.vue'
import DisplayUpdateEnd from '@/components/common/display-update-end.vue'
import DisplayLog from '@/components/common/display-log.vue'
import DisplayModel from '@/components/common/display-model.vue'
import DeviceItemLogs from '@/components/device/item-logs.vue'
import FirmwareStore from '@/store/firmware'
import ModelStore from '@/store/model'

@Component<DeviceItemContent>({
  components: {
    GmapCustomMarker,
    DisplayTime,
    DisplayUpdateStart,
    DisplayUpdateEnd,
    DisplayLog,
    DisplayModel,
    DeviceItemLogs
  },
  created () {
    this.subscribe()
  },
  destroyed () {
    this.destroy()
  }
})
export default class DeviceItemContent extends Vue {
  @Prop({ type: String }) id!: string | null
  @Prop({ type: Boolean }) solo!: boolean
  @Prop({ type: Object }) device!: Device | null
  @Prop({ type: Boolean }) expandedAll!: boolean

  // ref = deviceCollection.doc(this.id)
  unsubscribe: Function | null = null
  subDevice: Device | null = null
  expanded = this.expandedAll || false
  editRemark = false
  editGroup = false
  paramsUpdate = false
  carTypeList= [
    { text: '선택없음', value: null },
    { text: '시내버스:11', value: 11 },
    { text: '농어촌버스:12', value: 12 },
    { text: '마을버스:13', value: 13 },
    { text: '시외버스:14', value: 14 },
    { text: '고속버스:15', value: 15 },
    { text: '전세버스:16', value: 16 },
    { text: '특수여객자동차:17', value: 17 },
    { text: '일반택시:21', value: 21 },
    { text: '개인택시:22', value: 22 },
    { text: '일반화물자동차:31', value: 31 },
    { text: '개별화물자동차:32', value: 32 },
    { text: '비사업용자동차:41', value: 41 }
  ]

  isValid = false
  params: Params = {
    carType: this.device?.setInfo?.carType || null,
    plateNo: this.device?.setInfo?.plateNo || null,
    vin: this.device?.setInfo?.vin || null,
    companyName: this.device?.setInfo?.companyName || null,
    companyNo: this.device?.setInfo?.companyNo || null,
    odoOffset: this.device?.setInfo?.odoOffset || null
  }

  inputRules = {
    carType: [(v: number) => (!v || (v > 0 && v <= 41)) || '자동차 유형을 확인하세요'],
    plateNo: [
      (v: string) => (!v || v.split(' ').length <= 1) || '띄어 쓰기를 확인하세요',
      (v: string) => (!v || this.checkPlateNo(v)) || '차량 등록 번호를 확인하세요'
      // (v: string) => (!v || (v.length >= 7 && v.length <= 9)) || '차량등록 번호을 확인하세요'
    ],
    vin: [
      (v: string) => (!v || v.split(' ').length <= 1) || '띄어 쓰기를 확인하세요',
      (v: string) => (!v || (v.length === 17 || v.length === 16)) || '차대 번호을 확인하세요'
    ],
    companyName: [
      (v: string) => (!v || v.split(' ').length <= 1) || '띄어 쓰기를 확인하세요',
      (v: string) => (!v || v.length <= 10) || '회사명은 10자리 이내입니다.'
    ],
    companyNo: [
      (v: string) => (!v || v.split(' ').length <= 1) || '띄어 쓰기를 확인하세요',
      (v: number) => (!v || (v >= 1000000000 && v <= 9999999999)) || '사업자 번호는 숫자 10자리입니다.'
    ],
    odoOffset: [(v: number) => (!v || (v >= 0 && v <= 9999999)) || 'ODO Offset를 확인하세요']
  }

  @Watch('expandedAll')
  onChangeExpandedAll (expanded: boolean) {
    this.expanded = expanded
  }

  get item () {
    if (!this.id) return this.device
    return this.subDevice
  }

  get groups () {
    if (!this.item) return []
    const model = ModelStore.items.find(item => item.id === this.item?.info.modelNo)
    if (!model) return []
    return model.groups
  }

  subscribe () {
    if (!this.id) return
    this.unsubscribe = deviceCollection.doc(this.id).onSnapshot(doc => {
      if (!doc.exists) return
      // setTimeout(() => {
      //   this.item = doc.data() || null
      // }, 2000)
      this.subDevice = doc.data() || null
    })
  }

  destroy () {
    if (this.unsubscribe) this.unsubscribe()
  }

  changeItemUpdate (item: Device) {
    deviceCollection.doc(item.id).set({ update: item.update }, { merge: true })
  }

  changeUpdateParams (item: Device) {
    if (item.updateParams) return
    deviceCollection.doc(item.id).set({ updateParams: false }, { merge: true })
  }

  setUpdateParams (item: Device) {
    Object.keys(this.params).map(param => {
      let value = this.params[param]
      if (value) {
        switch (param) {
          case 'carType':
          case 'odoOffset':
            value = parseInt(value.toString().replace(' ', ''))
            break
          default:
            value = value.toString().replace(' ', '')
            break
        }
      }
    })
    // if (this.params.carType && !this.params.plateNo) this.params.carType = null
    // if (!this.params.carType && this.params.plateNo) this.params.plateNo = null
    deviceCollection.doc(item.id).set({ updateParams: true, setInfo: this.params }, { merge: true })
  }

  clearItemUpdate (item: Device) {
    deviceCollection.doc(item.id).set({ update: 0 }, { merge: true })
  }

  changeItemLog (item: Device) {
    deviceCollection.doc(item.id).set({ log: item.log }, { merge: true })
  }

  changeItemView (item: Device) {
    deviceCollection.doc(item.id).set({ view: item.view }, { merge: true })
  }

  changeItemRemark () {
    deviceCollection.doc(this.item?.id).set({ remark: this.item?.remark }, { merge: true })
    this.editRemark = false
  }

  changeItemGroup () {
    deviceCollection.doc(this.item?.id).set({ group: this.item?.group }, { merge: true })
  }

  firmwareSelects (item: Device) {
    return FirmwareStore.items.filter(firmware => firmware.modelNo === item.info.modelNo)
  }

  setFirmware (device: Device, firmware: Firmware) {
    deviceCollection.doc(device.id).set({
      firmware: firmwareCollection.doc(firmware.id)
    }, { merge: true })
  }

  azi2arrow (azimuth: number) {
    // if (!item.speed) return 'mdi-pause-circle-outline'
    let aridx = Number((azimuth + 22.5) / 45)
    if (aridx > 7) aridx = 0
    return `mdi-arrow-up-bold-circle-outline mdi-rotate-${aridx * 45}`
  }

  time2color (time: Date) {
    const diff = this.$moment().diff(time, 'seconds')
    if (diff < 120) return 'success'
    else if (diff >= 120) return 'error'
  }

  carType2icon (type: number) {
    switch (type) {
      case 11: return 'mdi-dump-truck'
      case 12: return 'mdi-bus'
      default: return 'mdi-car'
    }
  }

  checkPlateNo (str: string) {
    const nineNumber = /^[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]{2}[0-9]{2}[|가-히]{1}[0-9]{4}$/i
    if (nineNumber.test(str)) return true
    const eightNumber = /^[0-9]{3}[|가-히]{1}[0-9]{4}$/i
    if (eightNumber.test(str)) return true
    const sevenNumber = /^[0-9]{2}[|가-히]{1}[0-9]{4}$/i
    if (sevenNumber.test(str)) return true
    return false
  }
}
